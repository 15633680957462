<template>
  <div
    v-if="!isLoading"
    class="assessment"
  >
    <SHeader
      breadcrumbs="Avaliações"
      :icon="assessmentType.imageUrl"
      :tabs="tabs"
      :title="assessment.name"
      toolbar
      @back="goBack()"
      @select-tab="goToView($event)"
    />

    <section>
      <Wrapper>
        <router-view @select-tab="selectTabFromRoute($event)" />
      </Wrapper>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SHeader from '@/components/SHeader'

export default {
  name: 'Assessment',

  components: {
    SHeader,
  },

  props: {
    applicationId: {
      type: [Number, String],
      required: true,
    },

    assessmentId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      tabs: [
        {
          title: 'Provas',
          index: 0,
          active: false,
          routeName: 'Exams',
        },
        {
          title: 'Resultados',
          index: 1,
          active: false,
          routeName: 'Results',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['assessmentType', 'assessment', 'application', 'isLoading']),
  },

  created() {
    this.loadAssessment()
  },

  destroyed() {
    this.clearExams()
  },

  methods: {
    ...mapActions([
      'getApplication',
      'getAssessment',
      'getExams',
      'clearExams',
      'getResults',
      'showMainLoading',
      'hideMainLoading',
    ]),

    async loadAssessment() {
      this.showMainLoading()
      const [application] = await Promise.all([
        this.getApplication(this.applicationId),
        this.getAssessment(this.assessmentId),
      ])

      await Promise.all([
        this.getExams({
          assessmentId: this.assessmentId,
          application,
        }),
      ])
      this.hideMainLoading()
    },

    goToView(indexTab) {
      const selectedTab = this.tabs.find((tab) => tab.index === indexTab)
      this.$router.replace({
        name: `assessment${selectedTab.routeName}`,
        params: { assessmentId: this.assessmentId },
      })
    },

    selectTabFromRoute(routeName) {
      const selectedTab = this.tabs
        .find((tab) => routeName.includes(tab.routeName))
      this.selectTab(selectedTab.index)
    },

    selectTab(index) {
      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        active: false,
      }))
      this.tabs = this.tabs.map(
        (tab) => (tab.index === index ? {
          ...tab,
          active: true,
        } : tab),
      )
    },
    goBack() {
      const routerName = this.assessment.product === 'CORRECTOR'
        ? 'personal-assessments'
        : 'assessments'

      this.$router.push({ name: routerName })
    },
  },
}
</script>
