<template>
  <div
    class="tab"
    :class="[
      `tab--${theme}`,
      { 'tab--active': isActive },
    ]"
  >
    <SasButton
      :size="size"
      theme="float"
      @click="$emit('click')"
    >
      {{ text }}
    </SasButton>
  </div>
</template>

<script>
export default {
  name: 'SHeaderTab',

  props: {
    text: {
      type: String,
      required: true,
    },

    isActive: Boolean,

    theme: {
      type: String,
      default: 'light',
    },

    size: {
      type: String,
      default: 'normal',
    },
  },
}
</script>

<style lang="sass">

.tab
  display: inline-block
  border-bottom: 4px solid none

  .btn--float
    margin-bottom: 4px
    text-decoration: none

  &--active
    opacity: 1

  &--light
    opacity: .75

    &.tab--active
      opacity: 1
      border-bottom: 4px solid $color-ink

      .btn--float
        color: $color-ink
</style>
