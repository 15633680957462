<template>
  <header
    class="header"
    :style="{ background: `${background}` }"
  >
    <Wrapper>
      <SHeaderToolbar
        v-if="toolbar"
        :breadcrumbs="breadcrumbs"
        :loading="loading"
        :title="title"
        @back="$emit('back')"
        @click="$emit('click')"
      >
        <slot
          slot="actions"
          name="actions"
        />
      </SHeaderToolbar>

      <div class="header-body">
        <div
          class="header-body__title"
          :style="{ width: hasRightSlot ? '70%' : '100%' }"
        >
          <div
            v-if="icon && !iconHidden"
            class="title-icon"
          >
            <img :src="getIcon()">
          </div>

          <skeleton-loader
            v-if="loading"
            :height="$tokens.size_xl"
            width="280px"
          />
          <h2
            v-else
            class="title-text"
            v-html="title"
          />
        </div>

        <slot
          v-if="hasRightSlot"
          name="right"
        />
      </div>

      <div
        v-if="visibleTabs"
        class="header-tabs"
      >
        <SHeaderTab
          v-for="tab in tabs"
          :key="`tab-${tab.index}`"
          :is-active="tab.active"
          :size="tabSize"
          :text="tab.title"
          @click="$emit('select-tab', tab.index)"
        />
      </div>
    </Wrapper>
  </header>
</template>

<script>
import SHeaderToolbar from '@/components/SHeaderToolbar'
import SHeaderTab from '@/components/SHeaderTab'

export default {
  name: 'SHeader',

  components: {
    SHeaderToolbar,
    SHeaderTab,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    breadcrumbs: {
      type: [String, Array],
      default: () => '',
    },

    icon: {
      type: String,
      default: 'book',
    },

    tabs: {
      type: Array,
      default: () => [],
    },

    background: {
      type: String,
      default: () => 'white',
    },

    toolbar: Boolean,

    iconHidden: Boolean,

    loading: Boolean,
  },

  data() {
    return {
      visibleTabs: false,
    }
  },

  computed: {
    hasRightSlot() {
      return this.$slots.right
    },

    tabSize() {
      return this.$mq.includes('small') ? 'small' : 'regular'
    },
  },

  watch: {
    tabs() {
      this.visibleTabs = this.tabs.some((tab) => tab)
    },
  },

  methods: {
    getIcon() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/${this.icon}`)
    },
  },
}
</script>

<style lang="sass">

.header
  width: 100%

  &-body
    display: flex
    align-items: center
    justify-content: space-between
    padding: 32px 0px
    color: $color-ink

    &__title
      display: flex
      align-items: center

      .title-icon
        background: #5196D6
        border-radius: 12px
        padding: 12px
        box-sizing: border-box
        margin-right: 32px
        +flex-center

        +mq-s
          margin-right: 16px

        img
          width: 32px
          height: 32px

          +mq-s
            width: 24px
            height: 24px

      .title-text

        +mq-s
          font-size: 18px
</style>
