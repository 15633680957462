<template>
  <div class="header-toolbar">
    <SasButton
      v-if="typeof breadcrumbs === 'string'"
      class="toolbar__breadcrumbs"
      theme="float"
      @click="$emit('back')"
    >
      <Icon
        slot="left"
        strike="white"
        type="arrow-left"
      />
      {{ breadcrumbs }}
    </SasButton>
    <div
      v-else
      class="toolbar__breadcrumbs__row"
    >
      <SasButton
        v-for="(item, index) in breadcrumbs"
        :key="index"
        class="toolbar__breadcrumbs__row__item"
        :disabled="index === breadcrumbs.length - 1"
        theme="float"
        @click="$emit('back')"
      >
        <Icon
          v-if="index === 0"
          slot="left"
          strike="white"
          type="arrow-left"
        />
        {{ item }}
      </SasButton>
    </div>

    <slot name="actions" />
  </div>
</template>

<script>
export default {
  name: 'SHeaderToolbar',
  props: {
    breadcrumbs: {
      type: [String, Array],
      required: true,
    },
  },
}
</script>

<style lang="sass">

.header-toolbar
  display: flex
  align-items: center
  justify-content: space-between
  padding-top: 16px

  .toolbar__breadcrumbs
    text-decoration: none

    +mq-m
      grid-template-columns: 100%

    &__row
      display: flex
      align-items: center

      &__item
        position: relative
        margin-right: 8px

        &:after
          content: '/'
          position: absolute
          right: -8px
          top: 50%
          transform: translateY(-50%)

        &:last-child
          &:after
            display: none
</style>
